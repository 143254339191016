












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    MEventsList: () => import("@/components/molecules/m-events-list.vue"),
    MEventsCalendar: () =>
      import("@/components/molecules/m-events-calendar.vue"),
  },

  setup() {
    return {};
  },
});
